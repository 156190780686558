import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Currency } from '@depay/local-currency';

import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const CreateAccount = () => {

    const [countries, setCountries] = useState('');
    const { isLoading: isloadingCountries, refetch: getCountries } = useQuery(
        "countries",
        async () => {
          return await apiClient.get("/api/countries");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setCountries(res.data.countries);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

    const dispatch = useDispatch();
    const initialLogin = {
        name: '',
        email: '',
        password: '',
        phone: '',
        country: '',
        currency: '',
        account_type: '',
        account_status: '',
        transfer_pin: '',
        account_number: ''
    }
   
    const [error, setErros] = useState('');
    
    const [CreateAccountForm, setCreateAccountForm] = useState(initialLogin);
    const appMode = useSelector((state) => state.mode.mode);
    const redirectTo = useSelector((state) => state.redirect.redirect);

    const navigate = useNavigate();

    const onChange = (e) =>
    setCreateAccountForm({ ...CreateAccountForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/create-account`, {
            name: CreateAccountForm.name,
            email: CreateAccountForm.email,
            password: CreateAccountForm.password,
            phone: CreateAccountForm.phone,
            country: CreateAccountForm.country,
            currency: CreateAccountForm.currency,
            account_type: CreateAccountForm.account_type,
            account_status: CreateAccountForm.account_status,
            transfer_pin: CreateAccountForm.transfer_pin,
            account_number: CreateAccountForm.account_number,

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

          },
          onError: (err) => {   
            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors) 

            
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }     
          },
        }
      );
      const onSubmit = async() =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      } 

      useEffect(() => {
        getCountries();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={0} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '1px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        Create User
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create new account
                    </Typography>


                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Full Name">Full Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            
                            type={'text'}
                            label="Full Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            
                            type={'text'}
                            label="Full Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            {(error !== '' && error.email) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="email"
                            type={'email'}
                            name="email"
                            
                            label="Email"
                            helperText={error.email}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="email"
                            type={'email'}
                            name="email"
                            
                            label="Email"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.email}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                            onChange={onChange}
                            id="password"
                            name="password"
                            
                            type={'text'}
                            label="Password"
                            />
                        </FormControl>

                        {(countries !== '' && countries.length > 0 )&&
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="country">Select Country</InputLabel>
                        {(error !== '' && error.country) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="country"
                        id="country"
                        name="country"
                        
                        label={`Select Country`}
                        helperText={error.country}
                        >
                        <MenuItem selected value=''>
                            Select Country
                        </MenuItem> 
                        {countries.map(country =>(
                            <MenuItem value={country.name}><img style={{height: "15px", width: "20px", marginRight: '4px'}} alt="Menubar Icon" src={country.flag} />
                            {country.name} </MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="country"
                            name="country"
                            
                            id="country"
                            label={`Select Country`}
                            >
                            <MenuItem selected value="">
                                Select Country
                            </MenuItem>              
                            {countries.map(country =>(
                            <MenuItem value={country.name}>
                                <img style={{height: "15px", width: "20px", marginRight: '4px'}} alt="Menubar Icon" src={country.flag} />
                                {country.name} </MenuItem>
                            ))} 
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.country}
                        </Typography>
                    </FormControl>
                        }

                    {(countries !== '' && countries.length > 0 )&&
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="currency">Select currency</InputLabel>
                        {(error !== '' && error.currency) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="currency"
                        id="currency"
                        name="currency"
                        
                        label={`Select currency`}
                        helperText={error.currency}
                        >
                        <MenuItem selected value=''>
                            Select currency
                        </MenuItem> 
                        {countries.map(currency =>(
                            <MenuItem value={currency.currency_code}>
                                <img style={{height: "15px", width: "20px", marginRight: '4px'}} alt="Menubar Icon" src={currency.flag} />
                                {currency.currency_code} ({currency.name})
                            </MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="currency"
                            name="currency"
                            
                            id="currency"
                            label={`Select currency`}
                            >
                            <MenuItem selected value="">
                                Select currency
                            </MenuItem>              
                            {countries.map(currency =>(
                            <MenuItem value={currency.currency_code}>
                                <img style={{height: "15px", width: "20px", marginRight: '4px'}} alt="Menubar Icon" src={currency.flag} />
                                {currency.currency_code} ({currency.name})
                             </MenuItem>
                            ))} 
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.currency}
                        </Typography>
                    </FormControl>
                        }

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="account_type">Select Account Type</InputLabel>
                        {(error !== '' && error.account_type) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="account_type"
                        id="account_type"
                        name="account_type"
                        
                        label={`Select Account Type`}
                        helperText={error.account_type}
                        >
                        <MenuItem selected value=''>
                            Select Account Type
                        </MenuItem> 
                        <MenuItem value="Savings Account">
                        Savings Account    
                        </MenuItem>
                        <MenuItem value="Current Account">
                        Current Account  
                        </MenuItem> 
                        <MenuItem value="Fixed Deposit Account">
                        Fixed Deposit Account  
                        </MenuItem> 
                        <MenuItem value="Salary Account">
                        Salary Account   
                        </MenuItem> 
                        <MenuItem value="Recurring Deposit Account">
                        Recurring Deposit Account  
                        </MenuItem>              
                       
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="account_type"
                            name="account_type"
                            
                            id="account_type"
                            label={`Select Account Type`}
                            >
                            <MenuItem selected value="">
                                Select Account Type
                            </MenuItem>              
                            <MenuItem value="Savings Account">
                            Savings Account    
                            </MenuItem>
                            <MenuItem value="Current Account">
                            Current Account  
                            </MenuItem> 
                            <MenuItem value="Fixed Deposit Account">
                            Fixed Deposit Account  
                            </MenuItem> 
                            <MenuItem value="Salary Account">
                            Salary Account   
                            </MenuItem> 
                            <MenuItem value="Recurring Deposit Account">
                            Recurring Deposit Account  
                            </MenuItem>  
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.account_type}
                        </Typography>
                    </FormControl>


                    
<FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="account_status">Select Account Status</InputLabel>
                        {(error !== '' && error.account_status) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="account_status"
                        id="account_status"
                        name="account_status"
                        
                        label={`Select Account Status`}
                        helperText={error.account_status}
                        >
                        <MenuItem selected value=''>
                            Select Account Status
                        </MenuItem> 
                        <MenuItem value="Active">
                        Active  
                        </MenuItem>
                        <MenuItem value="Inactive">
                        Inactive 
                        </MenuItem> 
                        <MenuItem value="disabled">
                            Disable OTP 
                        </MenuItem>  
                                    
                       
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="account_status"
                            name="account_status"
                            
                            id="account_status"
                            label={`Select Account Status`}
                            >
                            <MenuItem selected value="">
                                Select Account Status
                            </MenuItem>   
                            <MenuItem value="Active">
                            Active  
                            </MenuItem>
                            
                            <MenuItem value="Inactive">
                            Inactive 
                            </MenuItem> 

                            <MenuItem value="disabled">
                                Disable OTP 
                            </MenuItem>  
                                         
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.account_status}
                        </Typography>
                    </FormControl>
                        


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="phone">Phone</InputLabel>
                            {(error !== '' && error.phone) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="phone"
                            name="phone"
                            
                            type={'tel'}
                            label="Phone"
                            helperText={error.phone}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="phone"
                            name="phone"
                            
                            type={'tel'}
                            label="Phone"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.phone}
                            </Typography>
                        </FormControl>

                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="Transfer Pin">Transfer Pin</InputLabel>
                        {(error !== '' && error.transfer_pin) ?
                        <OutlinedInput
                        error
                        onChange={onChange}
                        id="transfer_pin"
                        name="transfer_pin"
                        
                        type={'text'}
                        label="Transfer Pin"
                        helperText={error.transfer_pin}
                        />
                        :
                        <OutlinedInput
                        onChange={onChange}
                        id="transfer_pin"
                        name="transfer_pin"
                        
                        type={'text'}
                        label="Transfer Pin"
                        />
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.transfer_pin}
                        </Typography>
                    </FormControl>

                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="Account Number">Account Number</InputLabel>
                        {(error !== '' && error.account_number) ?
                        <OutlinedInput
                        error
                        onChange={onChange}
                        id="account_number"
                        name="account_number"
                        
                        type={'text'}
                        label="Account Number"
                        helperText={error.account_number}
                        />
                        :
                        <OutlinedInput
                        onChange={onChange}
                        id="account_number"
                        name="account_number"
                        
                        type={'text'}
                        label="Account Number"
                        />
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.account_number}
                        </Typography>

                        </FormControl>
                    </CardContent>                    
                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create Account'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreateAccount
